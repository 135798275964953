import * as React from 'react';
import clsx from 'clsx';
import { ALERT } from '@core/constants';
import { useAlert } from '@core/hooks';
// @ts-ignore
import Modal from '@components/Modal';
import * as styles from './Alert.module.scss';


export default function Alert() {
  const componentId = React.useId();
  const { isShown, type, title, message, buttons, onAccept, onCancel, resetState } = useAlert();
  // @ts-ignore
  const { accept, cancel } = buttons;
  const isAlert = type === ALERT.TYPE.ALERT;

  const handleRequestClose = () => {
    isAlert && onCancel();
  };

  const handleAfterClose = () => {
    resetState();
  };

  return (
    <Modal
      isOpen={isShown!}
      showCloseButton={isAlert}
      onRequestClose={handleRequestClose}
      // @ts-ignore
      onAfterClose={handleAfterClose}
    >
      <h3 className={styles.title}>{title}</h3>
      <section className={styles.message}>
        {message?.split('\n').map((paragraph, index) => (
          <p key={`${componentId}-${index}`}>{paragraph}</p>
        ))}
      </section>
      <section className={styles.controls}>
        <button
          className={clsx(styles.button, {
            [styles.button__accept]: accept?.type === ALERT.CONTROL.ACCENT,
            [styles.button__danger]: accept?.type === ALERT.CONTROL.DANGER,
          })}
          type="button"
          onClick={onAccept}
        >
          {accept?.label}
        </button>
        {!isAlert && (
          <button
            className={clsx(styles.button, {
              [styles.button__accept]: cancel?.type === ALERT.CONTROL.ACCENT,
              [styles.button__danger]: cancel?.type === ALERT.CONTROL.DANGER,
            })}
            type="button"
            onClick={onCancel}
          >
            {cancel?.label}
          </button>
        )}
      </section>
    </Modal>
  );
}

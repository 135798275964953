import * as React from 'react';
import { parse } from 'qs';
import { useIsomorphicLayoutEffect, useUser } from '@core/hooks';
import { RouteComponentProps } from '@reach/router';


export type CheckoutLocationState = {
  isGuest?: boolean;
  isPaymentSkipped?: boolean;
  orderId?: string | undefined;
}

type RouteProps = RouteComponentProps<{ location: { state: CheckoutLocationState } }>;

export default function useCheckout({ location, navigate }: RouteProps) {
  const { isGuest, clearGuestStatus } = useUser();
  const [isGuestFlow, setIsGuestFlow] = React.useState(false);

  // @ts-ignore
  const query: string | null = location?.search ? parse(location.search, { ignoreQueryPrefix: true }) : null;
  // @ts-ignore
  const orderId = query?.m_orderid || location?.state?.orderId || null;
  const isPaymentSkipped = location?.state?.isPaymentSkipped || false;

  useIsomorphicLayoutEffect(() => {
    const guest = isGuest() || location?.state?.isGuest;
    if (query) {
      // @ts-ignore
      let state: LocationState = { orderId: query?.m_orderid };
      if (guest) {
        state.isGuest = true;
      }
      navigate?.(location!.pathname, { state, replace: true });
    }
    if (guest) {
      setIsGuestFlow(true);
      clearGuestStatus();
    }
  }, []);

  return {
    isGuestFlow,
    orderId,
    isPaymentSkipped,
  };
};

import * as React from 'react';
import clsx from 'clsx';
import * as styles from './ScrollWidthHolder.module.scss';


type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode | React.ReactNode[] | string;
  tagName?: string;
};

export default function ScrollWidthHolder({ children, tagName = 'div', className, ...props }: Props) {
  return React.createElement(tagName, { ...props, className: clsx(styles.container, className) }, children);
}

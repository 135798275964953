import * as React from 'react';
import clsx from 'clsx';
import Link from '@components/Link';
import * as styles from './NavLink.module.scss';


type Props = {
  to: string;
  label: string;
  className?: string;
  partiallyActive?: boolean;
  onClick?: React.MouseEventHandler;
}

export default function NavLink({ label, className, partiallyActive = false, ...other }: Props) {
  return (
    <Link
      {...other}
      className={clsx(styles.link, className)}
      activeClassName={styles.active}
      data-text={label}
      partiallyActive={partiallyActive}
      state={{
        noScroll: true,
      }}
    >
      <span>{label}</span>
    </Link>
  )
};

// noinspection JSIgnoredPromiseFromCall

import { useRecoilState, useRecoilValue } from 'recoil';
import { uniq, pick, omit, map, difference, isString, isUndefined } from 'lodash';
import { useCaptcha } from '@core/hooks';
import { FIELD, CART } from '@core/constants';
import { cartState, cartStateDefaults, libraryStatsState, userStatsState } from '@core/atoms';
import * as api from '@core/api';
import { LocalStorage } from '@core/classes';
import { calcPrice } from '@core/utils';
import { API, State, AnyObject, CartProductItem, Coupon, CartItemType, CartBundleItem } from '@interface/common';
import { Bundle, Product } from '@interface/gatsby';


const store = new LocalStorage<State.Cart>('cart');

function buildProductItem(productId: string, coupon: Coupon | null): CartProductItem {
  return {
    createdAt: new Date(),
    productId,
    discount: belongsToCoupon(productId, coupon) ? coupon!.discount! : 0,
  };
}

function buildBundleItem({ id, items }: Bundle): CartBundleItem {
  return {
    createdAt: new Date(),
    bundleId: id,
    products: items.map(item => item.product.id),
  };
}

function belongsToCoupon(productId: string, coupon: Coupon | null) {
  return coupon && !coupon.error && (!coupon.products || coupon.products?.includes(productId));
}

function checkIsCouponExpired({ coupon }: State.Cart) {
  return coupon?.expireAt && !coupon.error && new Date() >= new Date(coupon.expireAt);
}

function getLocalState() {
  if (store.isSupported) {
    let state = store.get() || cartStateDefaults;
    if (state.coupon && checkIsCouponExpired(state)) {
      let { code } = state.coupon;
      state = resetDiscount(state);
      store.set(state);
      state.coupon = { code, error: 'Coupon has expired' };
    }
    return state;
  } else {
    return cartStateDefaults;
  }
}

function saveLocalState(state: State.Cart) {
  if (store.isSupported) {
    let products = state.products;
    let num = products.ids.length;
    if ((num >= 0 && !checkIsCouponExpired(state)) || num > 0) {
      store.set({
        ...state,
        proposes: {
          ids: [],
          modal: false,
        },
      });
    } else {
      clearLocalState();
    }
  }
}

function clearLocalState() {
  store.remove();
}

function collectData<Type>(state: { ids: string[], entities: AnyObject<Type> }, idFieldName: string) {
  return state.ids.map<Partial<Type>>((id: string) => {
    return pick(state.entities[id], [idFieldName, FIELD.CREATED_AT]);
  });
}

function addProduct(prevState: State.Cart, productId: string): State.Cart {
  let prevProducts = prevState.products;
  return {
    ...prevState,
    products: {
      ...prevProducts,
      ids: uniq([productId, ...prevProducts.ids]),
      entities: { ...prevProducts.entities, [productId]: buildProductItem(productId, prevState.coupon) },
    },
  };
}

function addProducts(prevState: State.Cart, productIds: string[]): State.Cart {
  let prevProducts = prevState.products;
  return {
    ...prevState,
    products: {
      ...prevProducts,
      ids: uniq([...productIds, ...prevProducts.ids]),
      entities: {
        ...prevProducts.entities,
        ...productIds.reduce((acc: AnyObject<CartProductItem>, i) => {
          acc[i] = buildProductItem(i, prevState.coupon);
          return acc;
        }, {}),
      },
    },
  };
}

function addBundle(prevState: State.Cart, bundle: Bundle): State.Cart {
  let { products, bundles } = prevState;
  let bundleProducts = bundle.items.map(i => i.product.id);
  return {
    ...prevState,
    products: {
      ...products,
      ids: products.ids.filter(id => !bundleProducts.includes(id)),
      entities: omit(products.entities, bundleProducts),
    },
    bundles: {
      ...bundles,
      ids: uniq([bundle.id, ...bundles.ids]),
      entities: { ...bundles.entities, [bundle.id]: buildBundleItem(bundle) },
    },
  };
}

function removeItem(prevState: State.Cart, entityId: string, type: CartItemType): State.Cart {
  let prevTypeState = prevState[type];
  return {
    ...prevState,
    [type]: {
      ...prevTypeState,
      ids: prevTypeState.ids.filter(id => id !== entityId),
      entities: omit(prevTypeState.entities, entityId),
    },
  };
}

function applyGlobalCoupon(prevState: State.Cart, coupon: Coupon | null): State.Cart {
  let prevProducts = prevState.products;
  let discount = coupon?.discount || 0;
  return {
    ...prevState,
    products: {
      ...prevProducts,
      entities: Object.keys(prevProducts.entities).reduce((acc: AnyObject<CartProductItem>, i) => {
        acc[i] = { ...prevProducts.entities[i], discount };
        return acc;
      }, {}),
    },
    coupon,
  };
}

function applyProductCoupon(prevState: State.Cart, coupon: Coupon, libraryIds?: string[]): State.Cart {
  let { products, bundles } = prevState;
  let bundled = Object.values(bundles.entities).reduce((acc: string[], bundle: CartBundleItem) => {
    return [...acc, ...bundle.products];
  }, []);
  let candidates = !isUndefined(libraryIds) ? difference(difference(coupon.products, libraryIds), products.ids) : [];
  let proposes = candidates.filter(id => !bundled.includes(id));
  return {
    ...prevState,
    products: {
      ...products,
      entities: {
        ...products.entities,
        ...coupon.products?.reduce((acc: AnyObject<CartProductItem>, id) => {
          let exist = products.entities[id] || {};
          acc[id] = {
            ...exist,
            discount: coupon.discount!,
          };
          return acc;
        }, {}),
      },
    },
    proposes: {
      ids: proposes,
      modal: proposes.length > 0,
    },
    coupon,
  };
}

function applyDiscount(prevState: State.Cart, coupon: Coupon, libraryIds?: string[]): State.Cart {
  return coupon.products ?
    applyProductCoupon(prevState, coupon, libraryIds) :
    applyGlobalCoupon(prevState, coupon);
}

function resetDiscount(prevState: State.Cart): State.Cart {
  let prevProducts = prevState.products;
  return {
    ...prevState,
    products: {
      ...prevProducts,
      entities: prevProducts.ids.reduce((acc: AnyObject<CartProductItem>, id) => {
        acc[id] = {
          ...prevProducts.entities[id],
          discount: 0,
        };
        return acc;
      }, {}),
    },
    proposes: {
      ids: [],
      modal: false,
    },
    coupon: null,
  };
}

export default function useCart() {
  const captcha = useCaptcha();
  const { isAuthenticated } = useRecoilValue(userStatsState);
  const library = useRecoilValue(libraryStatsState);
  const [cart, setCart] = useRecoilState(cartState);

  function remoteRemoveCoupon(token: string) {
    if (!isAuthenticated) {
      if (captcha.execute) {
        captcha.execute('remove_coupon')
        .then((tokenV3) => {
          api.removeCoupon(token, tokenV3);
        });
      }
    } else {
      api.removeCoupon(token);
    }
  }

  function save(newState: State.Cart) {
    if (isAuthenticated) {
      const { products, bundles } = newState;
      api.updateCart(products.ids, bundles.ids);
    } else {
      saveLocalState(newState);
    }
  }

  const fetchLocalState = async () => {
    if (!isAuthenticated) {
      setCart(getLocalState());
    }

    return Promise.resolve();
  };

  const hasLocalCartItems = () => {
    let state = store.get();
    return state && (state.products.ids?.length > 0 || state.bundles.ids?.length > 0);
  };

  const isInCart = (itemId: string, type: CartItemType) => cart[type].ids.includes(itemId);

  const isInBundle = (itemId: string) => {
    return Object.values(cart.bundles.entities).some((bundle) => bundle.products?.includes(itemId));
  };

  const isLastInCart = (type: CartItemType | undefined = undefined) => {
    if (isUndefined(type)) {
      const ids = [...cart.products.ids, ...cart.bundles.ids];
      return ids.length === 1;
    } else {
      return cart[type].ids.length === 1;
    }
  };

  const getCartData = (overwrite: boolean = false): API.Params.Cart => {
    const { products, bundles, coupon } = cart;
    return {
      couponToken: coupon?.token && !coupon.error ? coupon.token : null,
      overwrite,
      products: collectData<CartProductItem>(products, FIELD.PRODUCT_ID),
      bundles: collectData<CartBundleItem>(bundles, FIELD.BUNDLE_ID),
    };
  };

  const getDiscount = (productId: string) => {
    const { coupon } = cart;
    if (belongsToCoupon(productId, coupon)) {
      return coupon!.discount;
    }
    return 0;
  };

  const hasDiscount = (productId: string) => {
    return belongsToCoupon(productId, cart.coupon);
  };

  const anyItemHasDiscount = (): boolean => {
    const { products, coupon } = cart;
    return products.ids.some((productId) => belongsToCoupon(productId, coupon));
  };

  const addProductToCart = (productId: string | string[]) => {
    if (isString(productId)) {
      if (!isInCart(productId, CART.ITEM.PRODUCTS)) {
        const newState = addProduct(cart, productId);
        setCart(newState);
        save(newState);
      }
    } else {
      let productIds = productId.filter((id) => !isInCart(id, CART.ITEM.PRODUCTS));
      const newState = addProducts(cart, productIds);
      setCart(newState);
      save(newState);
    }
  };

  const addBundleToCart = (bundle: Bundle) => {
    if (!isInCart(bundle.id, CART.ITEM.BUNDLES)) {
      const newState = addBundle(cart, bundle);
      setCart(newState);
      save(newState);
    }
  };

  const removeFromCart = (id: string, type: CartItemType) => {
    if (isInCart(id, type)) {
      let newState;
      if (type === CART.ITEM.PRODUCTS) {
        const { coupon } = cart;
        newState = removeItem(cart, id, CART.ITEM.PRODUCTS);
        if (isLastInCart(CART.ITEM.PRODUCTS) && coupon?.token) {
          if (!isAuthenticated) {
            remoteRemoveCoupon(coupon.token);
          }
          newState.coupon = null;
        }
      } else {
        newState = removeItem(cart, id, CART.ITEM.BUNDLES);
      }
      setCart(newState);
      save(newState);
    }
  };

  const populateCart = ({ products, bundles, coupon }: API.LogIn.Response['cart']) => {
    let newState = {
      ...cart,
      products: {
        ...cart.products,
        ids: map(products, FIELD.PRODUCT_ID),
        entities: products.reduce((acc: AnyObject, item) => {
          acc[item.productId] = { ...item, createdAt: new Date(item.createdAt) };
          return acc;
        }, {}),
      },
      bundles: {
        ...cart.bundles,
        ids: map(bundles, FIELD.BUNDLE_ID),
        entities: bundles.reduce((acc: AnyObject, item) => {
          acc[item.bundleId] = item.products;
          return acc;
        }, {}),
      },
    };
    if (coupon) {
      newState = applyDiscount(newState, coupon);
    }

    setCart(newState);
  };

  const clearCart = () => {
    setCart(cartStateDefaults);
  };

  const applyCoupon = (coupon: Coupon) => {
    const newState = applyDiscount(cart, coupon, library.ids);
    setCart(newState);

    if (!isAuthenticated) {
      saveLocalState(newState);
    }
  };

  const removeCoupon = () => {
    const { coupon } = cart;
    if (coupon?.token) {
      remoteRemoveCoupon(coupon.token);
    }

    const newState = resetDiscount(cart);
    setCart(newState);

    if (!isAuthenticated) {
      saveLocalState(newState);
    }
  };

  const closeProposesModal = () => {
    setCart(prevState => ({
      ...prevState,
      proposes: {
        ...prevState.proposes,
        modal: false,
      },
    }));
  };

  const clearProposes = () => {
    setCart(prevState => ({
      ...prevState,
      proposes: {
        ...prevState.proposes,
        ids: [],
      },
    }));
  };

  const calculateTotals = (products: AnyObject<Product>, bundles: AnyObject<Bundle>) => {
    const [productsSubtotal, productsTotal] = cart.products.ids.reduce((acc, id) => {
      let product = products[id];
      let item = cart.products.entities[id];
      if (product) {
        acc[0] = acc[0] + product.price;
        acc[1] = acc[1] + calcPrice(product.price, item.discount);
      }
      return acc;
    }, [0, 0]);

    const [bundlesSubtotal, bundlesTotal] = cart.bundles.ids.reduce((acc, id) => {
      let bundle = bundles[id];
      if (bundle) {
        acc[0] = acc[0] + bundle.price;
        acc[1] = acc[1] + calcPrice(bundle.price, bundle.discount);
      }
      return acc;
    }, [0, 0]);

    let originalAmount = productsSubtotal + bundlesSubtotal;
    let totalAmount = productsTotal + bundlesTotal;
    let savedAmount = originalAmount - totalAmount;

    return { products, originalAmount, savedAmount, totalAmount };
  };

  return {
    isInCart,
    isInBundle,
    isLastInCart,
    getCartData,
    hasLocalCartItems,
    getDiscount,
    hasDiscount,
    anyItemHasDiscount,
    addProductToCart,
    addBundleToCart,
    removeFromCart,
    populateCart,
    clearCart,
    applyCoupon,
    removeCoupon,
    closeProposesModal,
    clearProposes,
    fetchLocalState,
    clearLocalState,
    calculateTotals,
  };
};

import Schema from 'validate';
import * as EmailValidator from 'email-validator';
import { isUndefined } from 'lodash';
import { passwordStrength, Result } from 'check-password-strength';
import PasswordDiversity from './password_diversity';
import { AnyObject } from '@interface/common';

type Rule = {
  required?: boolean;
  use?: AnyObject;
  length?: AnyObject;
  enum?: string[];
};

export const lt = (value: string, comparison: number): boolean => {
  return value ? Number(value) < comparison : true;
};

export const lte = (value: string, comparison: number): boolean => {
  return value ? Number(value) <= comparison : true;
};

export const gt = (value: string, comparison: number): boolean => {
  return value ? Number(value) > comparison : true;
};

export const gte = (value: string, comparison: number): boolean => {
  return value ? Number(value) >= comparison : true;
};

export const isStrongPassword = (value: string): boolean => {
  const feedback: Result<{}> = passwordStrength(value, PasswordDiversity);
  return value ? feedback.id >= 4 : true;
};

export const isEqualToField = (fieldName: string, ...args: any[]): boolean => {
  return args[0] ? args[0] === args[1]?.[fieldName] : true;
};

export const isAccepted = (value: boolean): boolean => {
  return !isUndefined(value) ? value : false;
};

export const isEmail = (value: string): boolean => {
  return value ? EmailValidator.validate(value) : true;
};

export const isDecimal = (value: string): boolean => {
  return value ? /^(\d+)(\.)?(\d+)?$/.test(value) : true;
};

export const isDateRequired = (value: Date | boolean): boolean => {
  return value instanceof Date;
};

export default class Validation {
  protected _schema: Schema;

  constructor(rules: AnyObject<Rule>, options?: AnyObject) {
    this._schema = new Schema(rules, options);
    this._schema.message({
      required: (): string => `Required field`,
      isStrongPassword: (): string => `Follow the instructions to set a strong password`,
      isEqualToField: (): string => `Confirmation doesn't match`,
      isAccepted: (): string => `Should be accepted`,
      isEmail: (): string => `Wrong email format`,
      isDecimal: (): string => `Should contain only digits/dot/comma`,
      isDateRequired: (): string => `Required field`,
      length: (
        path: string,
        values: AnyObject,
        opts: { min?: number, max?: number },
      ): string => {
        const minLength = opts.min;
        const currentLength = values[path].length;
        return `Should be at least ${minLength} symbols, currently: ${currentLength}`;
      },
    });
  }

  public validate(values: AnyObject): any {
    const result: AnyObject = this._schema.validate(values);
    return result.reduce(
      (
        acc: AnyObject,
        { path, message }: { path: string; message: string },
      ) => {
        acc[path] = message;
        return acc;
      },
      {},
    );
  }

  public setMessages(rules: AnyObject): void {
    this._schema.message(rules);
  }

  public getMessage(name: string) {
    // @ts-ignore
    return this._schema.messages[name]();
  }
}

import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { useCaptcha } from '@core/hooks';
import * as api from '@core/api';
import { ERROR } from '@core/constants';
import Validation, { focusOnErrorDecorator, handleFieldError, isEmail } from '@core/forms';
import { GoogleReCaptcha } from '@components/thirdparty';
import Message from '@components/Message';
import TextInput from '@components/forms/TextInput';
import ButtonSubmit from '@components/forms/ButtonSubmit';
import { RouteComponentProps } from '@reach/router';
import { API } from '@interface/common';
import * as styles from './NewsletterForm.module.scss';

const _PREFIX = 'newsletter-';

const FORM_ID = _PREFIX + 'form';
const CAPTCHA_ID = _PREFIX + 'captcha';

const schema = new Validation({
  email: {
    required: true,
    use: { isEmail },
  },
});

const focusOnError = focusOnErrorDecorator(FORM_ID);


type FormValues = API.SubscribeNewsletter.Form;

export default function NewsletterForm(_props: RouteComponentProps) {
  const [isSuccess, setIsSuccess] = React.useState(false);
  const captcha = useCaptcha();

  const handleValidateForm = React.useCallback((values: FormValues) => schema.validate(values), []);

  const handleSubmitForm = React.useCallback(async ({ email }: FormValues): Promise<any> => {
    const errorField = 'email';
    if (!captcha.execute) {
      return handleFieldError(errorField, ERROR.CAPTCHA_NOT_READY);
    }

    if (captcha.tokenV2) {
      try {
        await api.subscribeNewsletter(email, { tokenV2: captcha.tokenV2 });
        setIsSuccess(true);
      } catch (error) {
        return handleFieldError(errorField, error.message);
      }
    }

    try {
      const tokenV3 = await captcha.execute('subscribe');
      await api.subscribeNewsletter(email, { tokenV3 });
      setIsSuccess(true);
    } catch (error) {
      captcha.reset();
      if (captcha.isLowScore(error) && !captcha.isRendered()) {
        captcha.render(CAPTCHA_ID);
        return false;
      } else {
        const message = error.errors ? error.errors[errorField][0] : error.message;
        return handleFieldError(errorField, message);
      }
    }
  }, [captcha.execute, captcha.tokenV2, captcha.isRendered]);

  return (
    <>
      {
        isSuccess ?
          <div className={styles.result}>
            <Message type="success">
              Thank you! You have successfully subscribed to our newsletter.
            </Message>
          </div> :
          <Form
            validate={handleValidateForm}
            // @ts-ignore
            decorators={[focusOnError]}
            onSubmit={handleSubmitForm}
            render={({ handleSubmit }) => (
              <form
                id={FORM_ID}
                className={styles.form}
                onSubmit={handleSubmit}
                noValidate
              >
                <Field
                  className={styles.input}
                  name="email"
                  type="email"
                  placeholder="john@domain.com"
                  autoComplete="on"
                  component={TextInput}
                  useLabel={false}
                  required
                />
                <GoogleReCaptcha id={CAPTCHA_ID}/>
                <ButtonSubmit
                  className={styles.button}
                  label="Subscribe"
                  scale
                />
              </form>
            )}
          />
      }
    </>
  );
}

import * as React from 'react';
import { Link } from 'react-scroll';
import { isUndefined } from 'lodash';
import clsx from 'clsx';
import { ARROW_DIRECTION } from '@core/constants';
import ArrowIcon from '@assets/arrow.svg';
import { ArrowDirectionType } from '@interface/common';
import * as styles from '@components/ArrowButton.module.scss';


export type Props = {
  to?: 'top' | string;
  href?: string;
  direction?: ArrowDirectionType;
  title?: string;
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

function ArrowButton({
  className,
  to,
  href,
  direction = ARROW_DIRECTION.TOP,
  title,
  onClick,
  ...other
}: Props) {
  const isToTop = to === ARROW_DIRECTION.TOP;
  const options = {
    duration: 400,
    smooth: false,
  };

  const handleScrollToTop = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const _renderIcon = () => (
    <ArrowIcon
      className={clsx(styles.icon, {
        [styles.top]: direction === ARROW_DIRECTION.TOP,
        [styles.bottom]: direction === ARROW_DIRECTION.BOTTOM,
        [styles.left]: direction === ARROW_DIRECTION.LEFT,
        [styles.right]: direction === ARROW_DIRECTION.RIGHT,
      })}
    />
  );

  if (!isUndefined(onClick)) {
    return (
      <button
        {...other}
        className={clsx(styles.button, className)}
        type="button"
        onClick={onClick}
      >
        {_renderIcon()}
      </button>
    );
  }

  if (isToTop) {
    return (
      <button
        {...other}
        type="button"
        className={clsx(styles.button, className)}
        title={isToTop ? 'Back to Top' : title}
        onClick={handleScrollToTop}
      >
        {_renderIcon()}
      </button>
    );
  }

  return (
    <Link
      {...other}
      {...options}
      to={to}
      href={href}
      className={clsx(styles.button, className)}
    >
      {_renderIcon()}
    </Link>
  );
}

export default React.memo(ArrowButton, () => true);

import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useLocation, WindowLocation } from '@reach/router';
import { RecoilRoot } from 'recoil';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer } from 'react-toastify';
import clsx from 'clsx';
import {
  useAuthentication,
  useCartLocalState,
  useModalOpenFix,
  useCustomViewportHeightUnits,
  useRoutes,
} from '@core/hooks';
import { isBrowser } from '@core/utils/env';
import { getSiteMetaData, mailToURL } from '@core/utils';
import { LABEL } from '@core/constants';
import { ContextProvider } from '@core/context/Context';
import Centerer from '@components/Centerer';
import Alert from '@components/Alert';
import ScrollWidthHolder from '@components/ScrollWidthHolder';
import ArrowButton from '@components/ArrowButton';
import MessageIcon from '@components/MessageIcon';
import Link from '@components/Link';
import ToastCloseButton from './parts/ToastCloseButton';
import HamburgerButton from './parts/HamburgerButton';
import Nav from './parts/Nav';
import NavLink from './parts/NavLink';
import Offcanvas from './parts/Offcanvas';
import CartWidget from './parts/CartWidget';
import LoginWidget from './parts/LoginWidget';
import NewsletterForm from './parts/NewsletterForm';
import Socials from './parts/Socials';
import Copyright from './parts/Copyright';
import LogoSVG from '@assets/logo.svg';
import { PageContext, PostgresData, ProductCategory, SiteData, SiteMetaData } from '@interface/gatsby';
import { MessageType } from '@interface/common';
import '../../styles.scss';
import * as styles from './index.module.scss';


type MainLayoutProps = {
  children: React.ReactNode,
  siteMetadata: SiteMetaData;
  categories: ProductCategory[];
  footerImage: any;
  pageContext: PageContext,
}

function MainLayout({ children, siteMetadata, categories, footerImage, pageContext }: MainLayoutProps) {
  // @ts-ignore
  const location: WindowLocation<{ modal?: boolean, path?: string }> = useLocation();
  const { route, routes, HOME, TERMS, EULA, PRIVACY } = useRoutes();
  const [isOffcanvasOpen, setIsOffcanvasOpen] = React.useState(false);

  useModalOpenFix();
  useCustomViewportHeightUnits();

  const isAuthenticating = useAuthentication();
  const isFetchingCart = useCartLocalState();
  const isCleanLayout = !isBrowser && pageContext?.layout === 'clean' || routes.checkout.includes(location.pathname);

  const props = { isAuthenticating, isFetchingCart, categories };
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  const handleOffcanvasClose = () => setIsOffcanvasOpen(false);

  const handleOffcanvasToggle = () => setIsOffcanvasOpen(prevState => !prevState);

  return (
    <>
      {!isCleanLayout && (
        <header
          id="header"
          className={styles.header}
        >
          <ScrollWidthHolder className={styles.header__inner}>
            <Link
              className={styles.header__logo}
              to={route(HOME)}
              onClick={handleOffcanvasClose}
              aria-label="Rebel Thorp"
            >
              <LogoSVG className={styles.logo}/>
            </Link>
            <Nav className={styles.header__nav}/>
            <div className={styles.header__widgets}>
              <CartWidget
                {...props}
                onClick={handleOffcanvasClose}
              />
              <LoginWidget
                {...props}
                className={styles.header__login}
              />
              <HamburgerButton
                className={styles.header__hamburger}
                isActive={isOffcanvasOpen}
                onClick={handleOffcanvasToggle}
              />
            </div>
          </ScrollWidthHolder>
        </header>
      )}
      {isCleanLayout && (
        <ScrollWidthHolder className={styles.cleanLayout__logo}>
          <LogoSVG className={styles.logo}/>
        </ScrollWidthHolder>
      )}
      {childrenWithProps}
      {!isCleanLayout && (
        <>
          <Offcanvas
            isActive={isOffcanvasOpen}
            categories={categories}
            onRequestClose={handleOffcanvasClose}
          />
          <footer
            id="footer"
            className={clsx(styles.footer, { [styles.marginBottom]: pageContext.floating })}
          >
            <ScrollWidthHolder>
              <Centerer>
                <ArrowButton to="top" className={styles.footer__scrollButton}/>
                <div className={styles.footer__inner}>
                  <div className={styles.footer__column}>
                    <h4 className={styles.footer__title}>Discover</h4>
                    <ul className={styles.footer__nav}>
                      <li className={styles.footer__nav__item}>
                        <NavLink to={route(TERMS)} label={LABEL.TERMS}/>
                      </li>
                      <li className={styles.footer__nav__item}>
                        <NavLink to={route(EULA)} label="End-User License Agreement (EULA)"/>
                      </li>
                      <li className={styles.footer__nav__item}>
                        <NavLink to={route(PRIVACY)} label={LABEL.PRIVACY}/>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.footer__column}>
                    <h4 className={styles.footer__title}>Subscribe to newsletter</h4>
                    <NewsletterForm/>
                  </div>
                  <div className={styles.footer__column}>
                    <h4 className={styles.footer__title}>Contact us</h4>
                    <Link
                      className={clsx('underline white', styles.footer__email)}
                      to={mailToURL({
                        to: siteMetadata.emails.contact,
                      })}
                    >
                      {siteMetadata.emails.contact}
                    </Link>
                  </div>
                  <div className={styles.footer__column}>
                    <h4 className={styles.footer__title}>Product support</h4>
                    <Link
                      className={clsx('underline white', styles.footer__email)}
                      to={mailToURL({
                        to: siteMetadata.emails.support,
                      })}
                    >
                      {siteMetadata.emails.support}
                    </Link>
                  </div>
                </div>
              </Centerer>
              <div className={styles.footer__socials}>
                <GatsbyImage
                  className={styles.footer__socials__image}
                  image={footerImage}
                  alt="Footer background"
                />
                <Centerer>
                  <Socials
                    className={styles.footer__socials__list}
                    items={siteMetadata.socials}
                  />
                </Centerer>
              </div>
              <Copyright className={styles.footer__copyright}/>
            </ScrollWidthHolder>
          </footer>
        </>
      )}
      <Alert/>
      <div id="recaptcha-v3"/>
      <ToastContainer
        limit={3}
        autoClose={4000}
        icon={({ type }: { type: MessageType }) => <MessageIcon type={type}/>}
        closeButton={<ToastCloseButton/>}
        newestOnTop
      />
    </>
  );
}

type AppData = SiteData &
  PostgresData<Record<'categories', ProductCategory[]>> &
  {
    footerImage: IGatsbyImageData;
  }

type AppProps = {
  children: React.ReactNode;
  pageContext: PageContext;
}

export default function App({ children, pageContext }: AppProps) {
  const data = useStaticQuery<AppData>(graphql`
      query Socials {
          site {
              siteMetadata {
                  emails {
                      contact
                      support
                  }
                  socials {
                      type
                      url
                  }
              }
          }
          postgres {
              categories: productCategoriesList(orderBy: SORT_ORDER_ASC) {
                  title
                  slug
                  url
              }
          }
          footerImage: file(
              absolutePath: { glob: "**/src/images/footer.jpg" }
          ) {
              childImageSharp {
                  gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      quality: 100
                  )
              }
          }
      }
  `);
  const siteMetaData = getSiteMetaData(data);
  const categories = data.postgres.categories;
  const footerImage = getImage(data.footerImage);

  return (
    <GoogleOAuthProvider
      clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_KEY}
        language="en"
        scriptProps={{
          defer: true,
        }}
        container={{
          element: 'recaptcha-v3',
          parameters: {
            badge: 'bottomright',
            theme: 'dark',
          },
        }}
      >
        <RecoilRoot>
          <ContextProvider>
            <MainLayout
              pageContext={pageContext}
              siteMetadata={siteMetaData}
              categories={categories}
              footerImage={footerImage}
            >
              {children}
            </MainLayout>
          </ContextProvider>
        </RecoilRoot>
      </GoogleReCaptchaProvider>
    </GoogleOAuthProvider>
  );
}

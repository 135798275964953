import { useRecoilState } from 'recoil';
import { keyBy, map, pick, uniq } from 'lodash';
import { libraryState, libraryStateDefaults } from '@core/atoms';
import * as api from '@core/api';
import { FILTER, FIELD } from '@core/constants';
import { API, State, AnyObject } from '@interface/common';
import { Product } from '@interface/gatsby';

function pushOrdersPage(prevState: State.Library, { orders, totalCount }: API.FetchOrders.Response): State.Library {
  const [ids, entities] = orders.reduce((acc: [string[], AnyObject], order) => {
    acc[0] = [...acc[0], order.id];
    acc[1][order.id] = {
      ...pick(order, [FIELD.ID, FIELD.CREATED_AT, FIELD.TOTAL]),
      ids: map(order.items, FIELD.ID),
      entities: keyBy(order.items, FIELD.ID),
    };
    return acc;
  }, [[], {}]);

  return {
    ...prevState,
    orders: {
      ...prevState.orders,
      ids: [...prevState.orders.ids, ...ids],
      entities: { ...prevState.orders.entities, ...entities },
      fetching: false,
      totalCount,
    },
  };
}

function pushUpdates(prevState: State.Library, { updates }: API.CheckUpdates.Response): State.Library {
  return {
    ...prevState,
    updates: {
      ...prevState.updates,
      ids: updates.ids,
      fetching: false,
    },
  };
}

function removeUpdate(prevState: State.Library, productId: string): State.Library {
  return {
    ...prevState,
    updates: {
      ...prevState.updates,
      ids: prevState.updates.ids.filter(id => id !== productId),
    },
  };
}

export default function useLibrary() {
  const [library, setLibrary] = useRecoilState(libraryState);

  function startFetchingOrders() {
    setLibrary((prevState => ({
      ...prevState,
      orders: {
        ...prevState.orders,
        fetching: true,
      },
    })));
  }

  const isInLibrary = (productId: string) => library.products.ids.includes(productId);

  const hasUpdate = (productId: string) => library.updates.ids.includes(productId);

  const fetchOrders = async () => {
    startFetchingOrders();
    try {
      const response = await api.fetchOrders(0);
      const newState = pushOrdersPage(library, response);
      setLibrary(newState);
    } catch (error) {
    }
  };

  const loadMoreOrders = async () => {
    if (!library.orders.fetching) {
      await fetchOrders();
    }
  };

  const checkUpdates = async () => {
    try {
      const response = await api.checkUpdates();
      const newState = pushUpdates(library, response);
      setLibrary(newState);
    } catch (error) {
    }
  };

  const markAsUpdated = (productId: string) => {
    const newState = removeUpdate(library, productId);
    setLibrary(newState);
  };

  const filterIds = (products: AnyObject<Product>, show: string, search: string) => {
    const query = search.toLowerCase();
    return library.products.ids.reduce((acc: string[], id) => {
      let product = products[id];
      if (product) {
        let categorySlug = product.category.slug;
        let title = product.title.toLowerCase();
        if ((show === FILTER.SHOW_ALL || show === categorySlug) && title.indexOf(query) > -1) {
          acc = [id, ...acc];
        }
      }
      return acc;
    }, []);
  };

  const populateLibrary = ({ ids }: API.LogIn.Response['library']) => {
    setLibrary(prevState => ({
      ...prevState,
      products: {
        ...prevState.products,
        ids: uniq([...ids, ...prevState.products.ids]),
      },
    }));
  };

  const clearLibrary = () => {
    setLibrary(libraryStateDefaults);
  };

  return {
    isInLibrary,
    hasUpdate,
    fetchOrders,
    loadMoreOrders,
    checkUpdates,
    markAsUpdated,
    filterIds,
    populateLibrary,
    clearLibrary,
  };
};

import { FORM_ERROR, getIn, Decorator } from 'final-form';
import createDecorator, { FocusableInput } from 'final-form-focus';
import { isString } from 'lodash';
import Validation, {
  lt,
  lte,
  gt,
  gte,
  isEqualToField,
  isStrongPassword,
  isAccepted,
  isDecimal,
  isDateRequired,
  isEmail,
} from './validation';
import { FieldMetaState } from 'react-final-form';
import { API, AnyObject } from '@interface/common';

export const parseNullFieldValue = (value: any) => value;

export const focusOnErrorDecorator = (formId: string): Decorator<AnyObject, AnyObject> => {
  const findInput = (inputs: FocusableInput[], errors: AnyObject)  => {
    return inputs.find((input) => {
      // @ts-ignore
      let form = input.closest('form');
      if (form?.id === formId) {
        return input.name && getIn(errors, input.name);
      }
    });
  }

  // @ts-ignore
  return createDecorator(undefined, findInput);
};

export const isError = (meta: FieldMetaState<any>): boolean => {
  return (
    (meta.touched && meta.submitFailed && meta.error) ||
    (meta.submitError && !meta.dirtySinceLastSubmit)
  );
};

const makeFormError = (message: string) => ({ [FORM_ERROR]: message });

export const handleFieldError = (field: string, message: string) => {
  return handleSubmissionError({ errors: { [field]: [message] } });
};

export const handleSubmissionError = (error: API.Error.Response | API.Error.FormField | string) => {
  if (isString(error)) {
    return makeFormError(error);
  }
  if (!error.errors && error.message) {
    return makeFormError(error.message);
  }
  return error.errors ?
    Object.keys(error.errors).reduce((acc: AnyObject, val) => {
      acc[val] = error.errors?.[val][0];
      return acc;
    }, {}) :
    undefined;
};

export { isEqualToField, isStrongPassword, isAccepted, isDecimal, isDateRequired, isEmail, lt, lte, gt, gte };
export default Validation;

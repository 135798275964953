import { atom, selector } from 'recoil';
import { ALERT } from '@core/constants';
import { State } from '@interface/common';

export const countdownState = atom<number>({
  key: 'Countdown',
  default: 0,
});

export const alertStateDefaults: State.Alert = {
  isShown: false,
  type: ALERT.TYPE.ALERT,
  title: null,
  message: null,
  buttons: {
    accept: {
      label: 'Ok',
      type: ALERT.CONTROL.ACCENT,
    },
    cancel: {
      label: 'Cancel',
      type: ALERT.CONTROL.DEFAULT,
    },
  },
  onAccept: () => {
  },
  onCancel: () => {
  },
};
export const alertState = atom<State.Alert>({
  key: 'Alert',
  default: alertStateDefaults,
});

export const userStateDefaults: State.User | undefined = undefined;
export const userState = atom<State.User | undefined>({
  key: 'User',
  default: userStateDefaults,
});

export const userStatsState = selector({
  key: 'UserStats',
  get: ({ get }) => {
    const user = get(userState);
    const isAuthenticated = !!user?.email;

    return {
      isAuthenticated,
    };
  },
});

export const cartStateDefaults: State.Cart = {
  coupon: null,
  products: {
    ids: [],
    entities: {},
  },
  bundles: {
    ids: [],
    entities: {},
  },
  proposes: {
    ids: [],
    modal: false,
  },
};

export const cartState = atom<State.Cart>({
  key: 'Cart',
  default: cartStateDefaults,
});

export const cartStatsState = selector({
  key: 'CartStats',
  get: ({ get }) => {
    const state = get(cartState);
    const { products, bundles, proposes } = state;
    const entities = products.entities;
    const coupon = state.coupon;
    const productsNum = products.ids.length;
    const hasProducts = productsNum > 0;
    const bundlesNum = bundles.ids.length;
    const hasBundles = bundlesNum > 0;
    const totalNum = productsNum + bundlesNum;
    const isEmpty = totalNum === 0;
    const isCouponApplied = coupon !== null;
    const couponHasError = !!(coupon?.error);

    return {
      products: products.ids,
      productsNum,
      hasProducts,
      bundles: bundles.ids,
      hasBundles,
      bundlesNum,
      entities,
      coupon,
      proposes: proposes.ids,
      totalNum,
      isEmpty,
      isCouponApplied,
      couponHasError,
    };
  },
});

export const libraryStateDefaults: State.Library = {
  products: {
    ids: [],
  },
  updates: {
    ids: [],
    fetching: true,
  },
  orders: {
    ids: [],
    entities: {},
    fetching: true,
    totalCount: 0,
  },
};

export const libraryState = atom<State.Library>({
  key: 'Library',
  default: libraryStateDefaults,
});

export const libraryStatsState = selector({
  key: 'LibraryStats',
  get: ({ get }) => {
    const { products } = get(libraryState);
    const ids = products.ids;
    const itemsNum = products.ids.length;
    const isEmpty = itemsNum === 0;

    return {
      ids,
      itemsNum,
      isEmpty,
    };
  },
});

export const ordersStatsState = selector({
  key: 'OrdersStats',
  get: ({ get }) => {
    const { orders } = get(libraryState);
    const ids = orders.ids;
    const entities = orders.entities;
    const itemsNum = orders.ids.length;
    const totalCount = orders.totalCount;
    const isFetching = orders.fetching;
    const isEmpty = itemsNum === 0;
    const hasMore = itemsNum < totalCount;
    const notFound = totalCount === 0;

    return {
      ids,
      entities,
      itemsNum,
      totalCount,
      isFetching,
      isEmpty,
      hasMore,
      notFound,
    };
  },
});

export const updatesStatsState = selector({
  key: 'UpdatesStats',
  get: ({ get }) => {
    const { updates } = get(libraryState);
    const ids = updates.ids;
    const totalCount = ids.length;
    const isFetching = updates.fetching;
    const notFound = totalCount === 0;

    return {
      ids,
      totalCount,
      isFetching,
      notFound,
    };
  },
});

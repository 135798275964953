import { useRecoilState, useRecoilValue } from 'recoil';
import { pick, omit } from 'lodash';
import { userState, userStatsState } from '@core/atoms';
import { useCart, useLibrary } from '@core/hooks/index';
import { LocalStorage } from '@core/classes';
import { API, Subscription, User } from '@interface/common';

type UserLocalState = {
  seen: boolean;
  guest?: boolean;
}

const store = new LocalStorage<UserLocalState>('user');

export default function useUser() {
  const [user, setUser] = useRecoilState(userState);
  const { isAuthenticated } = useRecoilValue(userStatsState);
  const { populateLibrary, clearLibrary } = useLibrary();
  const { populateCart, clearCart, clearLocalState } = useCart();

  const getProfile = () => {
    return pick(user, ['name', 'email', 'country', 'company', 'bio']);
  };

  const hasAccount = () => {
    const state = store.get();
    return state && state.seen;
  }

  const isGuest = () => {
    const state = store.get();
    return state? state.guest : false;
  }

  const signIn = ({ user, cart, library }: API.LogIn.Response, guest: boolean = false) => {
    setUser(user);
    populateCart(cart);
    populateLibrary(library);
    clearLocalState();
    let localState: UserLocalState = { seen: true };
    if (guest) {
      localState.guest = true;
    }
    store.set(localState);
  };

  const signOut = () => {
    setUser(undefined);
    clearCart();
    clearLibrary();
    store.remove();
  };

  const clearGuestStatus = () => {
    const localState = store.get();
    if (localState) {
      store.set(omit(localState, ['guest']));
    }
  }

  const markPasswordAsSet = (isPasswordSet: boolean) => {
    setUser((state) => ({ ...state!, isPasswordSet }));
  };

  const updateProfile = (user: User) => {
    setUser(user);
  };

  const updateNewsletterPrefs = (subscriptions: Subscription[]) => {
    setUser((state) => ({ ...state!, subscriptions }));
  };

  return {
    user,
    isAuthenticated,
    getProfile,
    hasAccount,
    isGuest,
    signIn,
    signOut,
    setUser,
    clearGuestStatus,
    markPasswordAsSet,
    updateProfile,
    updateNewsletterPrefs,
  };
};

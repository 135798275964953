import { isDebug, hasLocalStorage } from '@core/utils/env';
import { b64DecodeUnicode, b64EncodeUnicode } from '@core/utils';

export default class LocalStorage<Type> {
  _key: string;
  isSupported: boolean;

  constructor(key: string) {
    this._key = `rebelthorp:${key}`;
    this.isSupported = hasLocalStorage;
  }

  private parse(data: string): Type {
    let str = isDebug ? data : b64DecodeUnicode(data);
    return JSON.parse(str);
  }

  private stringify(data: Type): string {
    let str = JSON.stringify(data);
    return isDebug ? str : b64EncodeUnicode(str);
  }

  public set(data: Type): void {
    if (!this.isSupported) return;
    localStorage.setItem(this._key, this.stringify(data));
  }

  public get(): Type | null {
    if (!this.isSupported) return null;
    let data = localStorage.getItem(this._key);
    return data ? this.parse(data) : null;
  }

  public remove(): void {
    if (!this.isSupported) return;
    localStorage.removeItem(this._key);
  }
}

import * as React from 'react';
import clsx from 'clsx';
import AvatarIcon from '@assets/avatar.svg';
import * as styles from './Avatar.module.scss';


type Props = {
  className?: string;
};

export default function Avatar({ className }: Props) {
  return (
    <div className={clsx(styles.container, className)}>
      <AvatarIcon className={styles.icon}/>
    </div>
  );
}

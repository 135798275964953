import * as React from 'react';
import { GatsbyLinkProps } from 'gatsby';
import { Link as BaseLink } from '@plugins/gatsby-plugin-modal-routing';
import clsx from 'clsx';
import { LinkGetProps } from '@reach/router';
import { AnyObject } from '@interface/common';


let memo: string = '';

type Props = GatsbyLinkProps<AnyObject> & {
  asModal?: boolean;
  external?: boolean;
}

export default function Link({
  children,
  to,
  className,
  activeClassName,
  asModal = false,
  external = false,
  partiallyActive,
  ...other
}: Props) {
  const isInternal = /^\/(?!\/)/.test(to);
  if (isInternal && !external) {
    const getProps = ({ location, href }: LinkGetProps) => {
      // @ts-ignore
      if (!location.state?.modal) {
        memo = location.pathname;
      }

      const isActive = partiallyActive ? memo.includes(href) : href === memo;

      return {
        className: clsx(className, activeClassName ? { [activeClassName]: isActive } : null),
      };
    };

    return (
      // @ts-ignore
      <BaseLink
        to={to}
        {...other}
        asModal={asModal}
        className={className}
        activeClassName={activeClassName}
        getProps={getProps}
        partiallyActive
      >
        {children}
      </BaseLink>
    );
  }

  const isMailTo = /^mailto:.*/.test(to);

  let props = external && !isMailTo ? { ...other, target: '_blank', rel: 'nofollow noopener noreferrer' } : other;
  return (
    <a href={to} {...props} className={className}>
      {/* @ts-ignore */}
      {children}
    </a>
  );
}

import * as React from 'react';
import clsx from 'clsx';
import { LABEL, OFFCANVAS } from '@core/constants';
import { useRoutes } from '@core/hooks';
import { addHtmlClassName, removeHtmlClassName } from '@core/utils';
import LoginWidget from './LoginWidget';
import Nav from './Nav';
import NavLink from './NavLink';
import * as styles from './Offcanvas.module.scss';
import { ProductCategory } from '@interface/gatsby';


type Props = {
  className?: string;
  isActive: boolean;
  categories: ProductCategory[];
  onRequestClose: () => void;
};

export default function Offcanvas({ className, isActive, categories, onRequestClose }: Props) {
  const { route, TERMS, EULA, PRIVACY } = useRoutes();
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    if (isActive) {
      setIsVisible(true);
      addHtmlClassName(OFFCANVAS.CLASSNAME.HTML_OPEN);
    }
  }, [isActive]);

  const handleTransitionEnd = () => {
    if (!isActive) {
      setIsVisible(false);
      removeHtmlClassName(OFFCANVAS.CLASSNAME.HTML_OPEN);
    }
  };

  return (
    <>
      <div
        className={clsx(styles.overlay, className, {
          [styles.active]: isActive,
          [styles.visible]: isVisible,
        })}
        onTransitionEnd={handleTransitionEnd}
        onClick={onRequestClose}
      />
      <aside
        className={clsx(styles.offcanvas, className, {
          [styles.active]: isActive,
        })}
        role="navigation"
      >
        <section className={styles.content}>
          <div className={styles.content__inner}>
            <div className={styles.content__centerer}>
              <h4 className={styles.title}>Discover</h4>
              <Nav
                className={styles.nav}
                childClassName={styles.nav__child}
                categories={categories}
                onClick={onRequestClose}
              />
              <h4 className={styles.title}>Legal</h4>
              <div className={styles.legal}>
                <NavLink
                  to={route(TERMS)}
                  label={LABEL.TERMS}
                  onClick={onRequestClose}
                />
                <NavLink
                  to={route(EULA)}
                  label={LABEL.EULA}
                  onClick={onRequestClose}
                />
                <NavLink
                  to={route(PRIVACY)}
                  label={LABEL.PRIVACY}
                  onClick={onRequestClose}
                />
              </div>
            </div>
          </div>
        </section>
        <section className={styles.widget}>
          <LoginWidget onClick={onRequestClose}/>
        </section>
      </aside>
    </>
  );
}

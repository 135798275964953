import * as React from 'react';
import clsx from 'clsx';
import * as styles from './GoogleReCaptchaDisclaimer.module.scss';


export default function GoogleReCaptchaDisclaimer() {
  const linkProps = {
    target: '_blank',
    rel: 'external noreferrer'
  }
  return (
    <div className={clsx('format', styles.container)}>
      <small>This site is protected by reCAPTCHA and the Google{' '}<br/>
        <a {...linkProps} href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a {...linkProps} href="https://policies.google.com/terms">Terms of Use</a> apply.
      </small>
    </div>
  );
}

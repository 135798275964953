import axios from 'axios';
import { stringify } from 'qs';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { SERVER_STATUS } from './constants';
import { API } from '@interface/common';

const api = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: 5000,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=utf-8',
  },
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'brackets' }),
});

api.interceptors.request.use(
  (config) => ({ ...config, data: decamelizeKeys(config.data) }),
);

api.interceptors.response.use(
  ({ data }) => camelizeKeys(data),
  (error) => {
    let output;
    if (error.response.data) {
      if (error.response.status === SERVER_STATUS.INTERNAL_SERVER_ERROR.code) {
        output = SERVER_STATUS.INTERNAL_SERVER_ERROR;
      } else {
        output = camelizeKeys(error.response.data);
      }
    } else {
      output = error;
    }
    return Promise.reject(output);
  },
);

export const subscribeNewsletter: API.SubscribeNewsletter.Request = async (email, captcha) => {
  return await api.post('/subscribe-newsletter', { email, captcha });
};

export const authWithProvider: API.AuthWithProvider.Request = async (provider, payload) => {
  return await api.post(`/auth/${provider}`, payload);
};

export const signUp: API.SignUp.Request = async (user, captcha, cart) => {
  return await api.post('/signup', { user, captcha, cart });
};

export const logIn: API.LogIn.Request = async (user, cart) => {
  return await api.post('/login', { user, cart });
};

export const requestPasswordReset: API.RequestPasswordReset.Request = async (user, captcha) => {
  return await api.post('/request-password-reset', { user, captcha });
};

export const resetPassword: API.ResetPassword.Request = async (user, token, cart) => {
  return await api.post('/reset-password', { user, token, cart });
};

export const validatePasswordResetToken: API.ValidatePasswordResetToken.Request = async (token) => {
  return await api.post('/validate-password-reset-token', { token });
};

export const confirmEmail: API.ConfirmEmail.Request = async (token) => {
  return await api.post('/confirm-email', { token });
};

export const authenticate: API.Authenticate.Request = async () => {
  return await api.post('/authenticate', {});
};

export const logOut: API.LogOut.Request = async () => {
  return await api.post('/logout', {});
};

export const resendEmailConfirmation: API.ResendEmailConfirmation.Request = async () => {
  return await api.post('/resend-email-confirmation', {});
};

export const updateProfile: API.UpdateProfile.Request = async (user) => {
  return await api.post('/update-profile', { user });
};

export const updateNewsletterPrefs: API.UpdateNewsletterPrefs.Request = async (user) => {
  return await api.post('/update-newsletter-prefs', { user });
};

export const updatePassword: API.UpdatePassword.Request = async (user) => {
  return await api.post('/update-password', { user });
};

export const setPassword: API.SetPassword.Request = async (user) => {
  return await api.post('/set-password', { user });
};

export const fetchOrders: API.FetchOrders.Request = async (offset = 0) => {
  return await api.post('/fetch-orders', { offset });
};

export const fetchPurchase: API.FetchPurchase.Request = async (orderId) => {
  return await api.post('/fetch-purchase', { orderId });
};

export const updateCart: API.UpdateCart.Request = async (products, bundles) => {
  return await api.post('/update-cart', { products, bundles });
};

export const applyCoupon: API.ApplyCoupon.Request = async (code, captcha) => {
  return await api.post('/apply-coupon', { code, captcha });
};

export const removeCoupon: API.RemoveCoupon.Request = async (token, captcha) => {
  return await api.post('/remove-coupon', { token, captcha });
};

export const checkout: API.Checkout.Request = async () => {
  return await api.post('/checkout', {});
};

export const checkUpdates: API.CheckUpdates.Request = async () => {
  return await api.post('/check-updates', {});
};

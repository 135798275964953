// import { Product } from '@interface/gatsby';


type AuthFlow = 'login' | 'sign_up';
type AuthMethod = 'Password' | 'Google' | 'Facebook';

const CMD = 'event';
const IS_PROD = process.env.NODE_ENV === 'production';


// const currency = 'USD';

export default function useAnalytics() {

  const auth = (flow: AuthFlow, method: AuthMethod) => {
    if (!IS_PROD) return;

    gtag(CMD, flow, {
      method,
    });
  };

  // const addToCart = (item: Product) => {
  //   if (!IS_PROD) return;
  //
  //   let price = item.price / 100;
  //
  //   gtag(CMD, 'add_to_cart', {
  //     currency,
  //     value: price,
  //     items: [
  //       {
  //         item_name: item.title,
  //         currency,
  //         item_category: item.category.title,
  //         price,
  //       },
  //     ],
  //   });
  // };

  // const removeFromCart = (item: Product) => {
  //   if (!IS_PROD) return;
  //
  //   let price = item.price / 100;
  //
  //   gtag(CMD, 'remove_from_cart', {
  //     currency,
  //     value: price,
  //     items: [
  //       {
  //         item_name: item.title,
  //         currency,
  //         item_category: item.category.title,
  //         price,
  //       },
  //     ],
  //   });
  // };

  // const viewCart = () => {
  //   if (!IS_PROD) return;
  //
  //   gtag(CMD, 'view_cart', {
  //     currency,
  //     value: 7.77,
  //     items: [
  //       {
  //         item_name: 'Stan and Friends Tee',
  //         coupon: 'SUMMER_FUN',
  //         currency,
  //         discount: 2.22,
  //         item_category: 'Apparel',
  //         price: 9.99,
  //         quantity: 1,
  //       },
  //     ],
  //   });
  // };

  // const beginCheckout = () => {
  //   if (!IS_PROD) return;
  //
  //   gtag(CMD, 'begin_checkout', {
  //     currency,
  //     value: 7.77,
  //     coupon: 'SUMMER_FUN',
  //     items: [
  //       {
  //         item_name: 'Stan and Friends Tee',
  //         coupon: 'SUMMER_FUN',
  //         currency,
  //         discount: 2.22,
  //         item_category: 'Apparel',
  //         price: 9.99,
  //         quantity: 1,
  //       },
  //     ],
  //   });
  // };

  // const purchase = () => {
  //   if (!IS_PROD) return;
  //
  //   gtag(CMD, 'purchase', {
  //     transaction_id: 'T_12345',
  //     affiliation: 'Google Merchandise Store',
  //     value: 25.42,
  //     currency,
  //     coupon: 'SUMMER_SALE',
  //     items: [
  //       {
  //         item_id: 'SKU_12345',
  //         item_name: 'Stan and Friends Tee',
  //         affiliation: 'Google Merchandise Store',
  //         coupon: 'SUMMER_FUN',
  //         currency: 'USD',
  //         discount: 2.22,
  //         index: 0,
  //         item_brand: 'Google',
  //         item_category: 'Apparel',
  //         item_category2: 'Adult',
  //         item_category3: 'Shirts',
  //         item_category4: 'Crew',
  //         item_category5: 'Short sleeve',
  //         item_list_id: 'related_products',
  //         item_list_name: 'Related Products',
  //         item_variant: 'green',
  //         location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
  //         price: 9.99,
  //         quantity: 1,
  //       },
  //       {
  //         item_id: 'SKU_12346',
  //         item_name: 'Google Grey Women\'s Tee',
  //         affiliation: 'Google Merchandise Store',
  //         coupon: 'SUMMER_FUN',
  //         currency: 'USD',
  //         discount: 3.33,
  //         index: 1,
  //         item_brand: 'Google',
  //         item_category: 'Apparel',
  //         item_category2: 'Adult',
  //         item_category3: 'Shirts',
  //         item_category4: 'Crew',
  //         item_category5: 'Short sleeve',
  //         item_list_id: 'related_products',
  //         item_list_name: 'Related Products',
  //         item_variant: 'gray',
  //         location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
  //         price: 20.99,
  //         promotion_id: 'P_12345',
  //         promotion_name: 'Summer Sale',
  //         quantity: 1,
  //       }],
  //   });
  // };

  return {
    auth,
    // addToCart,
    // removeFromCart,
    // viewCart,
    // beginCheckout,
    // purchase,
  };
};

import { useEffect } from 'react';
import { FallbackWorker } from 'fallback-worker';
import { AnyObject } from '@interface/common';
import { useUser } from '@core/hooks/index';


const MESSAGE_TYPE = 'rt:email_confirmation';

export default function useEmailConfirmation(subscribe = false) {
  const { setUser } = useUser();

  function init(): FallbackWorker {
    return new FallbackWorker('/notifier.js');
  }

  function notify(payload: AnyObject): void {
    let worker = init();
    worker.postMessage({
      type: MESSAGE_TYPE,
      payload,
    });
    worker.terminate();
  }

  useEffect(() => {
    let worker: FallbackWorker;

    const handler = (data: any) => setUser(state => ({ ...state, ...data }));

    if (subscribe) {
      worker = init();
      worker.on('message', ({ data }: AnyObject) => {
        if (data?.type === MESSAGE_TYPE) {
          handler(data.payload);
          worker.terminate();
        }
      });
    }

    return () => {
      worker?.terminate();
    };
  }, []);

  return { notify };
};

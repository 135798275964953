// extracted by mini-css-extract-plugin
export var cleanLayout__logo = "_1ee86";
export var footer = "_4ab55";
export var footer__column = "fd600";
export var footer__copyright = "_92864";
export var footer__email = "_27eeb";
export var footer__inner = "_6de93";
export var footer__nav = "_98071";
export var footer__nav__item = "_8ab5a";
export var footer__scrollButton = "_6b4fc";
export var footer__socials = "_07bf3";
export var footer__socials__image = "efc44";
export var footer__socials__list = "_13612";
export var footer__title = "ec3f1";
export var header = "_2745a";
export var header__hamburger = "_4c413";
export var header__inner = "cba83";
export var header__login = "_4527e";
export var header__logo = "_6b1f9";
export var header__nav = "b8aad";
export var header__widgets = "_870cb";
export var logo = "_665f4";
export var marginBottom = "fe0ef";
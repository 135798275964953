import * as React from 'react';


type Props = {
  className?: string;
};

export default function Copyright({ className }: Props) {
  const currentYear = (new Date()).getFullYear();
  return (
    <div className={className}>
      <span>{`© 2017-${currentYear} Rebel Thorp • All rights reserved`}</span>
    </div>
  );
}

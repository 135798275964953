import * as React from 'react';
import { useCart } from '@core/hooks/index';

export default function useCartLocalState() {
  const { fetchLocalState } = useCart();
  const [isFetching, setIsFetching] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      await fetchLocalState();
      setIsFetching(false);
    })();
  }, []);

  return isFetching;
};

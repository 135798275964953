import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { FallbackWorker } from 'fallback-worker';
import { countdownState } from '@core/atoms';
import { LocalStorage } from '@core/classes';
import { AnyObject } from '@interface/common';

const TIMEOUT = 60;

const store = new LocalStorage<{ expireAt: string }>('countdown');

export default function useCountDown(subscribe = false) {
  const [count, setCountdown] = useRecoilState(countdownState);

  if (!store.isSupported) {
    return undefined;
  }

  function init(): FallbackWorker {
    return new FallbackWorker('/countdown.js');
  }

  function start(worker?: FallbackWorker): void {
    worker = worker || init();
    worker.postMessage(TIMEOUT);
    setCountdown(TIMEOUT);
    saveToCache();
  }

  function restartFromCache(worker: FallbackWorker): void {
    const cache = store.get();
    if (cache) {
      const diff: number = Date.parse(cache.expireAt) - Date.now();
      if (diff > 0) {
        const timeout = Math.ceil(diff / 1000);
        worker.postMessage(timeout);
        setCountdown(timeout);
      } else {
        store.remove();
      }
    }
  }

  function saveToCache(): void {
    const now = new Date();
    const expireAt = new Date(now.getTime() + TIMEOUT * 1000);
    store.set({ expireAt: expireAt.toString() });
  }

  useEffect(() => {
    let worker: FallbackWorker;

    const handler = (data: any) => setCountdown(data);

    if (subscribe) {
      worker = init();
      worker.on('message', ({ data }: AnyObject) => {
        handler(data);
      });
      restartFromCache(worker);
    }

    return () => {
      worker?.terminate();
    };
  }, []);

  const isActive = count > 0;

  return { start, count, isActive };
};

import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { CART } from '@core/constants';
import { cartStatsState } from '@core/atoms';
import { AnyObject, CartSummaryRow } from '@interface/common';
import { Bundle, Product } from '@interface/gatsby';

export default function useMergeCartItems(products: AnyObject<Product>, bundles: AnyObject<Bundle>) {
  const cart = useRecoilValue(cartStatsState);

  // @ts-ignore
  return React.useMemo<CartSummaryRow[]>(() => [...cart.bundles, ...cart.products].map((id: string) => {
    return products[id] ?
      { type: CART.ITEM.PRODUCTS, ...products[id] } :
      { type: CART.ITEM.BUNDLES, ...bundles[id], ids: bundles[id].items.map(i => i.product.id) };
  }), [cart]);
};

import * as React from 'react';

const PRODUCTS_BASE: string = '/products';
const CHECKOUT_BASE: string = '/checkout';
const ACCOUNT_BASE: string = '/account';

export const pages = {
  HOME: '/',
  PRODUCTS: PRODUCTS_BASE,
  BUNDLES: '/bundles',
  GUIDES: '/guides/animation',
  FAQ: '/faq',
  CART: '/cart',
  BILLING: '/billing',
  CONFIRM_ORDER: '/confirm',
  CHECKOUT_SUCCESS: '/success',
  CHECKOUT_FAILURE: '/failure',
  TERMS: '/terms-of-use',
  EULA: '/eula',
  PRIVACY: '/privacy-policy',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CONFIRM_EMAIL: '/confirm-email',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  ACCOUNT: ACCOUNT_BASE,
  ORDERS_HISTORY: '/purchases',
  PROFILE: '/profile',
  SECURITY: '/security',
  NEWSLETTER: '/newsletter',
};

export const pagePath = (path: string): string => {
  if ([
    pages.BUNDLES,
  ].includes(path)) {
    return PRODUCTS_BASE + path;

  } else if ([
    pages.RESET_PASSWORD,
    pages.ORDERS_HISTORY,
    pages.PROFILE,
    pages.SECURITY,
    pages.NEWSLETTER,
  ].includes(path)) {
    return ACCOUNT_BASE + path;

  } else if ([
    pages.BILLING,
    pages.CONFIRM_ORDER,
    pages.CHECKOUT_SUCCESS,
    pages.CHECKOUT_FAILURE,
  ].includes(path)) {
    return CHECKOUT_BASE + path;
  }

  return path;
};

const cleanPath = (path: string): string => {
  return path.replace('/', '');
}

const checkoutRoutes = (): string[] => {
  return [
    pages.BILLING,
    pages.CONFIRM_ORDER,
  ].map(pagePath);
};

const checkoutResultRoutes = (): string[] => {
  return [
    pages.CHECKOUT_SUCCESS,
    pages.CHECKOUT_FAILURE,
  ].map(pagePath);
};

const authRoutes = (): string[] => {
  return [
    pages.LOGIN,
    pages.SIGNUP,
    pages.FORGOT_PASSWORD,
  ].map(pagePath);
};

const privateRoutes = (): string[] => {
  return [
    pages.ACCOUNT,
    pages.ORDERS_HISTORY,
    pages.PROFILE,
    pages.SECURITY,
    pages.NEWSLETTER,
  ].map(pagePath);
};

export default function useRoutes() {
  return React.useMemo(() => {
    return {
      ...pages,
      _CHECKOUT_BASE: CHECKOUT_BASE,
      _ACCOUNT_BASE: ACCOUNT_BASE,
      routes: {
        checkout: checkoutRoutes(),
        checkoutResult: checkoutResultRoutes(),
        auth: authRoutes(),
        private: privateRoutes(),
      },
      route: pagePath,
      clean: cleanPath,
    };
  }, []);
};

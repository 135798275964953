import * as React from 'react';
import clsx from 'clsx';
import { ThemeType, SpinnerSizeType } from '@interface/common';
import * as styles from './Spinner.module.scss';


type Props = {
  className?: string;
  size?: SpinnerSizeType;
  theme?: ThemeType;
  overlay?: boolean;
}

export default function Spinner({ className, size = 'normal', theme = 'light', overlay = false }: Props) {
  return (
    <span className={clsx(styles.container, className, { [styles.overlay]: overlay })}>
      <span className={clsx(styles.loader, {
        [styles.small]: size === 'small',
        [styles.normal]: size === 'normal',
        [styles.light]: theme === 'light',
        [styles.dark]: theme === 'dark',
      })}>
        Loading...
      </span>
    </span>
  );
}

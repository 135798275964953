import { addHtmlClassName, removeHtmlClassName } from '@core/utils';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

export default function useHtmlClassName(className: string) {
  useIsomorphicLayoutEffect(() => {
    addHtmlClassName(className);

    return () => {
      removeHtmlClassName(className);
    };
  }, []);
};

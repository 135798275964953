import * as React from 'react';

type State = {
  activeCollapsible: string | null;
  setActiveCollapsible: (id:  string) => void
};

type Props = {
  children: React.ReactNode;
};

const defaultContextValue: State = {
  activeCollapsible: null,
  setActiveCollapsible: (_id: string) => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ContextProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...defaultContextValue,
    };
  }

  setActiveCollapsible = (id: string) => {
    this.setState(state => ({
      ...state,
      activeCollapsible: id,
    }));
  };

  render() {
    return (
      <Provider
        value={{ ...this.state, setActiveCollapsible: this.setActiveCollapsible }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export { Consumer as default, ContextProvider };

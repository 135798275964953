import { useRecoilState } from 'recoil';
import { alertState, alertStateDefaults } from '../atoms';
import { State } from '@interface/common';


type AlertOptions = Omit<State.Alert, 'isShown' | 'type' | 'onCancel'>
type ConfirmOptions = Omit<State.Alert, 'isShown' | 'type'>

export default function useAlert() {
  const [alert, setAlert] = useRecoilState(alertState);

  const showAlert = (options: AlertOptions, onAccept?: State.Alert['onAccept']) => {
    setAlert({
      ...alertStateDefaults,
      ...options,
      buttons: {
        ...alertStateDefaults.buttons,
        ...options.buttons,
      },
      isShown: true,
      type: 'alert',
      onAccept,
    });
  };

  const showConfirm = (
    options: ConfirmOptions,
    onAccept?: State.Alert['onAccept'],
    onCancel?: State.Alert['onCancel'],
  ) => {
    setAlert({
      ...alertStateDefaults,
      ...options,
      buttons: {
        ...alertStateDefaults.buttons,
        ...options.buttons,
      },
      isShown: true,
      type: 'confirm',
      onAccept,
      onCancel,
    });
  };

  const onAccept = () => {
    alert.onAccept?.();
    setAlert(prevState => ({ ...prevState, isShown: false }));
  };

  const onCancel = () => {
    alert.onCancel?.();
    setAlert(prevState => ({ ...prevState, isShown: false }));
  };

  const resetState = () => {
    setAlert(alertStateDefaults);
  };

  return {
    ...alert,
    showAlert,
    showConfirm,
    resetState,
    onAccept,
    onCancel,
  };
};

import * as React from 'react';
import { Link } from 'gatsby';
import { useRecoilValue } from 'recoil';
import { cartStatsState } from '@core/atoms';
import { useRoutes } from '@core/hooks';
import CartIcon from '@assets/cart.svg';
import * as styles from './CartWidget.module.scss';


type Props = {
  isAuthenticating: boolean;
  isFetchingCart: boolean;
  onClick?: React.MouseEventHandler;
}

export default function CartWidget({onClick}: Props) {
  const { totalNum } = useRecoilValue(cartStatsState);
  const { route, CART } = useRoutes();

  return (
    <Link
      className={styles.container}
      activeClassName={undefined}
      to={route(CART)}
      onClick={onClick}
      aria-label="Shopping cart"
    >
      {totalNum > 0 && <span className={styles.count}>{totalNum}</span>}
      <CartIcon className={styles.icon}/>
    </Link>
  );
}

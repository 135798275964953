import { stringify } from 'qs';
import { isString, pick } from 'lodash';
import { DEFAULT_LOCALE, BREAKPOINT, LAYOUT } from '@core/constants';
import { SiteData } from '@interface/gatsby';
import { AnyObject, BreakpointType } from '@interface/common';


export const b64EncodeUnicode = (str: string) => {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    (_match, p1) => {
      // @ts-ignore
      return String.fromCharCode('0x' + p1);
    }));
};

export const b64DecodeUnicode = (str: string) => {
  return decodeURIComponent(atob(str).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
};

export const minTwoDigits = (n: number): string => {
  return (n < 10 ? '0' : '') + n;
};

export const formatDate = (date: string, short: boolean = false) => {
  return new Date(date).toLocaleString(DEFAULT_LOCALE, {
    year: 'numeric',
    month: short ? 'short' : 'long',
    day: 'numeric',
  });
};

export const formatThousand = (num: number, usePostfix = false): string => {
  return num < 1e3 ? `${num}` : +(num / 1e3).toFixed(1) + (usePostfix ? 'K' : '');
};

const price = new Intl.NumberFormat(DEFAULT_LOCALE, {
  style: 'currency',
  currency: 'USD',
});

export const formatPrice = (number: number) => {
  return price.format(number / 100);
};

export const pluralize = (word: string, amount?: number) => {
  if (amount !== undefined && amount === 1) {
    return word;
  }
  const plural: { [key: string]: string } = {
    '(quiz)$': '$1zes',
    '^(ox)$': '$1en',
    '([m|l])ouse$': '$1ice',
    '(matr|vert|ind)ix|ex$': '$1ices',
    '(x|ch|ss|sh)$': '$1es',
    '([^aeiouy]|qu)y$': '$1ies',
    '(hive)$': '$1s',
    '(?:([^f])fe|([lr])f)$': '$1$2ves',
    '(shea|lea|loa|thie)f$': '$1ves',
    'sis$': 'ses',
    '([ti])um$': '$1a',
    '(tomat|potat|ech|her|vet)o$': '$1oes',
    '(bu)s$': '$1ses',
    '(alias)$': '$1es',
    '(octop)us$': '$1i',
    '(ax|test)is$': '$1es',
    '(us)$': '$1es',
    '([^s]+)$': '$1s',
  };
  const irregular: { [key: string]: string } = {
    'move': 'moves',
    'foot': 'feet',
    'goose': 'geese',
    'sex': 'sexes',
    'child': 'children',
    'man': 'men',
    'tooth': 'teeth',
    'person': 'people',
  };
  const uncountable: string[] = [
    'sheep',
    'fish',
    'deer',
    'moose',
    'series',
    'species',
    'money',
    'rice',
    'information',
    'equipment',
    'bison',
    'cod',
    'offspring',
    'pike',
    'salmon',
    'shrimp',
    'swine',
    'trout',
    'aircraft',
    'hovercraft',
    'spacecraft',
    'sugar',
    'tuna',
    'you',
    'wood',
  ];
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
    return word;
  }

  for (const w in irregular) {
    const pattern = new RegExp(`${w}$`, 'i');
    const replace = irregular[w];
    if (pattern.test(word)) {
      return word.replace(pattern, replace);
    }
  }

  for (const reg in plural) {
    const pattern = new RegExp(reg, 'i');
    if (pattern.test(word)) {
      return word.replace(pattern, plural[reg]);
    }
  }
  return word;
};

export interface MailToURLOptions {
  to: string;
  subject?: string;
  body?: string[];
}

export const mailToURL = (options: MailToURLOptions | string): string => {
  const mailTo = (email: string) => `mailto:${email}`;
  if (isString(options)) {
    return mailTo(options);
  }
  let config: AnyObject = pick(options, 'subject');
  config = options.body ? { ...config, body: options.body.join('%0d') } : config;
  return mailTo(options.to) + stringify(config, { addQueryPrefix: true, encode: false });
};

export const calcPrice = (price: number, percent: number = 0): number => {
  if (percent === 0) return price;
  let multiplier = (100 - percent) / 100;
  return Math.floor(price * multiplier);
};

export const getSiteMetaData = (data: SiteData) => {
  return data.site.siteMetadata;
};

export const addCssCustomProperty = (name: string, value: string): void => {
  document.documentElement.style.setProperty(name, value);
};

export const addHtmlClassName = (className: string): void => {
  const html = document.documentElement;
  if (!html.classList.contains(className)) {
    html.classList.add(className);
  }
};

export const removeHtmlClassName = (className: string): void => {
  const html = document.documentElement;
  if (html.classList.contains(className)) {
    html.classList.remove(className);
  }
};

export const px = (...args: number[]): string => {
  return args.map(arg => `${arg}px`).join(' ');
};

export const bp = (name: BreakpointType, minMax: 0 | 1) => {
  return BREAKPOINT.VALUE[name][minMax];
};

export const getContentPaddingTop = () => {
  if (window.matchMedia('(min-width: 46.25em) and (max-width: 89.99em)').matches) {
    return LAYOUT.PADDING_TOP_TABLET;
  } else if (window.matchMedia('(min-width: 90em)').matches) {
    return LAYOUT.PADDING_TOP_WIDE;
  } else {
    return 0;
  }
};

export const getElementOffset = (hash: string, scrollOffset: number): number => {
  const scrollTargetId = decodeURI(hash.slice(1));
  const element = document.getElementById(scrollTargetId);
  if (element) {
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;

    return elementPosition - scrollOffset;
  } else {
    return 0;
  }
};

export const getScrollOffset = (filter: boolean = false): number => {
  const paddingTop = getContentPaddingTop();
  if (filter) {
    return LAYOUT.TOPBAR_HEIGHT + LAYOUT.FILTER_HEIGHT + paddingTop;
  } else {
    return LAYOUT.TOPBAR_HEIGHT + paddingTop;
  }
};

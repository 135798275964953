import * as React from 'react';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { Link } from '@plugins/gatsby-plugin-modal-routing';
import { LABEL } from '@core/constants';
import { useOnClickOutside, useRoutes, useUser } from '@core/hooks';
import AccountMenu from '@components/account/AccountMenu';
import Spinner from '@components/Spinner';
import Avatar from './Avatar';
import LoginIcon from '@assets/log-in.svg';
import ArrowIcon from '@assets/arrow.svg';
import * as styles from './LoginWidget.module.scss';


type Props = {
  isAuthenticating?: boolean;
  className?: string;
  onClick?: () => void;
}

export default function LoginWidget({ isAuthenticating, className, onClick }: Props) {
  const location = useLocation();
  const { route, routes, LOGIN, SIGNUP } = useRoutes();
  const { user, isAuthenticated } = useUser();
  const [isShown, setIsShown] = React.useState(false);
  const widgetRef = React.useRef(null);

  const isNotAuthPath = React.useMemo(() => !routes.auth.includes(location.pathname), [location.pathname]);

  const handleMenuToggle = React.useCallback(() => setIsShown((state => !state)), []);

  const handleMenuHide = React.useCallback(() => {
    setIsShown(false);
  }, []);

  const handleItemClick = React.useCallback(() => {
    handleMenuHide();
    onClick?.();
  }, []);

  const handleSignInLinkClick = React.useCallback((event: React.SyntheticEvent) => {
    if (location.pathname === route(LOGIN)) {
      event.preventDefault();
    }
    onClick?.();
  }, [location]);

  const handleSignUpLinkClick = React.useCallback((event: React.SyntheticEvent) => {
    if (location.pathname === route(SIGNUP)) {
      event.preventDefault();
    }
    onClick?.();
  }, [location]);

  useOnClickOutside(widgetRef, handleMenuHide);

  if (isAuthenticating) {
    return (
      <div className={clsx(styles.container, styles.container__auth, styles.container__loading, className)}>
        <Spinner size="small"/>
      </div>
    );
  }

  if (isAuthenticated) {
    return (
      <div className={clsx(styles.container, styles.container__auth, className)} ref={widgetRef}>
        <button
          className={clsx(styles.authorized, styles.trigger, { [styles.triggerActive]: isShown })}
          type="button"
          onClick={handleMenuToggle}
        >
          <Avatar/>
          <span className={styles.username}>{user?.name}</span>
          <ArrowIcon className={clsx(styles.arrow, { [styles.arrowActive]: isShown })}/>
        </button>
        {isShown && (
          <div className={styles.menu}>
            <div className={styles.menu__welcome}>
              <p className={styles.menu__caption}>Welcome,</p>
              <p className={styles.menu__name}>{user!.name}</p>
            </div>
            <AccountMenu
              activeClassName={styles.active}
              onItemClick={handleItemClick}
            />
          </div>
        )}
      </div>
    );
  }

  const linkState = {
    noScroll: true,
  };
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.unauthorized}>
        <Link
          to={route(SIGNUP)}
          asModal={isNotAuthPath}
          onClick={handleSignUpLinkClick}
          state={linkState}
          data-text={LABEL.SIGNUP}
        >
          {LABEL.SIGNUP}
        </Link>
        <Link
          to={route(LOGIN)}
          className={clsx('button small light', styles.button)}
          asModal={isNotAuthPath}
          onClick={handleSignInLinkClick}
          state={linkState}
        >
          <LoginIcon className={styles.button__icon}/>
          <span>{LABEL.LOGIN}</span>
        </Link>
      </div>
    </div>
  );
}

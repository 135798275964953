import * as React from 'react';
import { navigate } from 'gatsby';
import { useLocation, globalHistory } from '@reach/router';
import { useIsomorphicLayoutEffect, useUser, useRoutes, useCart } from '@core/hooks';
import * as api from '@core/api';


export default function useAuthentication() {
  const location = useLocation();
  const { isAuthenticated, signIn, hasAccount } = useUser();
  const { hasLocalCartItems } = useCart();
  const { route, routes, LOGIN, ACCOUNT, CART, BILLING, CONFIRM_ORDER } = useRoutes();
  const [isAuthenticating, setIsAuthenticating] = React.useState<boolean>(false);

  const navigateTo = async (path: string) => await navigate(route(path), { replace: true });

  useIsomorphicLayoutEffect(() => {
    if (!isAuthenticated && hasAccount()) {
      setIsAuthenticating(true);
    }
  }, []);

  React.useEffect(() => {
    if (!isAuthenticated) {
      (async () => {
        try {
          const response = await api.authenticate();
          signIn(response);

          if (routes.auth.includes(location.pathname)) {
            await navigateTo(ACCOUNT);
          } else if (
            location.pathname === route(BILLING) ||
            // @ts-ignore
            location.pathname === route(CONFIRM_ORDER) && !location.state?.isGuest
          ) {
            await navigateTo(CART);
          }
        } catch (error) {
          if (routes.private.includes(location.pathname)) {
            await navigateTo(LOGIN);
          } else if (routes.checkout.includes(location.pathname) && !hasLocalCartItems()) {
            await navigateTo(CART);
          }
        } finally {
          hasAccount() && setIsAuthenticating(false);
        }
      })();
    }

    globalHistory.listen(async ({ action, location }) => {
      if (action === 'PUSH' && !isAuthenticated && !hasAccount() && routes.private.includes(location.pathname)) {
        await navigateTo(LOGIN);
      }
    });

  }, []);

  return isAuthenticating;
};

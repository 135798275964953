import { getImage } from 'gatsby-plugin-image';
import { GalleryImage, Product } from '@interface/gatsby';


export default function useProduct(product: Product) {

  const hasVideo = () => product.videos.length > 0;

  const getLatestRelease = () => {
    return product.latestRelease.length > 0 ? product.latestRelease[0] : null;
  };

  const getGalleryImages = () => {
    let items = [];
    if (hasVideo()) {
      let video = product.videos[0];
      items.push({
        video,
        originalClass: 'gallery-video',
        thumbnail: `https://img.youtube.com/vi/${video.videoId}/mq2.jpg`,
        thumbnailAlt: `${product.title} video thumbnail`,
        thumbnailWidth: 120,
        thumbnailHeight: 68,
        thumbnailClass: 'gallery-thumb gallery-thumb--video',
      })
    }
    return product.images.reduce((acc: any[], item: GalleryImage, idx: number) => {
      let original = getImage(item.original);
      let thumbnail = getImage(item.thumbnail);

      let num = idx + (hasVideo() ? 2 : 1);
      let itemProps = {
        original,
        originalAlt: item.meta?.alt || `${product.title} image ${num}`,
        originalWidth: original?.width,
        originalHeight: original?.height,
        thumbnail,
        thumbnailAlt: item.meta?.alt  || `${product.title} thumbnail ${num}`,
        thumbnailWidth: thumbnail?.width,
        thumbnailHeight: thumbnail?.height,
        thumbnailClass: 'gallery-thumb',
      };
      return [...acc, itemProps];
    }, items);
  }

  return {
    hasVideo,
    latestRelease: getLatestRelease(),
    galleryImages: getGalleryImages()
  };
};

import * as React from 'react';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { useAnalytics, useCart } from '@core/hooks';
import * as api from '@core/api';
import { DEFAULT_LOCALE, LABEL, ERROR } from '@core/constants';
import BaseIdentityButton from './BaseIdentityButton';
import GoogleSVG from '@assets/google-logo.svg';
import * as styles from './GoogleIdentityButton.module.scss';
import { API } from '@interface/common';


type Props = {
  text: 'signin' | 'signup' | 'continue';
  onSuccess: (response: API.AuthWithProvider.Response) => void;
  overwriteCart?: boolean;
}

export default function GoogleIdentityButton({ text, onSuccess, overwriteCart = false }: Props) {
  const { getCartData } = useCart();
  const tracking = useAnalytics();
  const [isHover, setIsHover] = React.useState(false);

  const textPrefix = () => {
    switch (text) {
      case 'signin':
        return LABEL.SIGNIN;
      case 'signup':
        return LABEL.SIGNUP;
      case 'continue':
        return LABEL.CONTINUE;
    }
  };

  const handleSuccess = async (res: CredentialResponse) => {
    if (!res.clientId || !res.credential) {
      toast.error(ERROR.AUTH_WITH_PROVIDER_FAILED);
      return;
    }

    try {
      const { credential } = res;
      const response = await api.authWithProvider('google', {
        credential,
        cart: getCartData(overwriteCart),
      });
      tracking.auth(response.flow === 'login' ? 'login': 'sign_up', 'Google');
      onSuccess(response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleError = () => toast.error('Login Failed');

  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  return (
    <div
      className={styles.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <GoogleLogin
        type="standard"
        theme="filled_black"
        size="large"
        width="420"
        locale={DEFAULT_LOCALE}
        text={`${text}_with`}
        shape="square"
        logo_alignment="left"
        onSuccess={handleSuccess}
        onError={handleError}
      />
      <BaseIdentityButton
        className={styles.fake}
        isHover={isHover}
        text={`${textPrefix()} with Google`}
      >
        <GoogleSVG className={styles.icon}/>
      </BaseIdentityButton>
    </div>
  );
}
